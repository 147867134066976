import React, { FC } from "react";
import classNames from "classnames";

import "./Progress.styles.scss";

/**
 * HTML progress bar.
 */
const Progress: FC<ProgressProps> = ({
  className = "",
  background = "Light",
  foreground = "Green",
  ...props
}) => (
  <progress
    className={classNames(
      "Progress",
      `Progress--Background-${background}`,
      `Progress--Foreground-${foreground}`,
      className
    )}
    {...props}
  />
);

export type ProgressProps = React.ProgressHTMLAttributes<HTMLProgressElement> & {
  /**
   * Design system background variant.
   */
  background?: "Light" | "Dark";
  /**
   * Design system foreground variant.
   */
  foreground?: "Blue" | "Green" | "Purple";
};

export default Progress;
