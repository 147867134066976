import { useState, useCallback } from "react";

import useIsMountedRef from "./useIsMountedRef";

/**
 * State hook with guarded `setState` only called when mounted.
 */
const useSafeState = (initialState: any) => {
  const isMountedRef = useIsMountedRef();
  const [state, setState] = useState(initialState);

  const safeSetState = useCallback((update: any) => {
    if (isMountedRef.current) {
      setState(update);
    }
  }, []);

  return [state, safeSetState];
};

export default useSafeState;
