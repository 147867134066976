import "./UserAvatar.styles.scss";

import classNames from "classnames";
import React, { FC } from "react";

import { Me } from "@/data/auth/types";

import Box from "../Box";

/**
 * Display first name initial as user avatar.
 */
const UserAvatar: FC<UserAvatarProps> = ({
  className = "",
  user = null,
  ...props
}) => (
  <Box className={classNames("UserAvatar", className)} {...props}>
    {user?.firstName?.[0] ?? ""}
  </Box>
);

export type UserAvatarProps = React.BaseHTMLAttributes<HTMLDivElement> & {
  user: Me;
};

export default UserAvatar;
