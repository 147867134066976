import React, { FC, forwardRef } from "react";
import classNames from "classnames";

import Box from "../Box";
import Transition from "../Transition";

import "./Tooltip.styles.scss";

/**
 * Animated tooltip component.
 *
 * @example
 * <Tooltip show={true} arrowProps={arrowProps}>
 *   This is a useful tip!
 * </Tooltip>
 */
const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(
  (
    {
      show = false,
      children,
      className = "",
      colorScheme = "Blue",
      arrowProps,
      ...props
    },
    ref
  ) => (
    <Transition variant="FadeIn" show={show}>
      <Box
        ref={ref}
        className={classNames(
          "Tooltip",
          `Tooltip--Color-${colorScheme}`,
          className
        )}
        {...props}
      >
        {children}
        <TooltipArrow {...arrowProps} />
      </Box>
    </Transition>
  )
);

Tooltip.displayName = "Tooltip";

export interface TooltipProps {
  /**
   * Whether the tooltip should be visible.
   */
  show?: boolean;
  /**
   * Any children elements to render.
   */
  children: any;
  /**
   * Background color scheme of the tooltip.
   */
  colorScheme?: "Blue" | "Purple";
  /**
   * `react-popover-tooltip` arrow props.
   */
  arrowProps: object;
  /**
   * HTML class.
   */
  className?: string;
}

/**
 * Arrow pointing from tooltip to it's trigger.
 */
const TooltipArrow: FC<TooltipArrowProps> = ({ className = "", ...props }) => (
  <Box className={classNames("Tooltip__Arrow", className)} {...props} />
);

export interface TooltipArrowProps {
  /**
   * HTML class.
   */
  className?: string;
}

export default Tooltip;
