import axios from "axios";
import setErrorHandlers from "../util/setErrorHandlers";
import { omitUndefinedValues } from "@/utils";
import { SESSION_TOKEN } from "@/clients/api";

const baseURL = "/api/api";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

const api = axios.create({
  baseURL: baseURL,
});
const usersAPI = axios.create({ baseURL: "/api/users" });
usersAPI.interceptors.request.use(config => {
  if (typeof window !== "undefined") {
    config.headers["X-Lateral-Session"] =
      window.localStorage.getItem(SESSION_TOKEN);
  }
  return config;
});

setErrorHandlers(api, { authRedirect: "/login/" });
setErrorHandlers(usersAPI, { authRedirect: "/login/" });

export const signUpUser = ({ email, recaptcha, invite_code }) =>
  usersAPI.post("/signup/", { email, recaptcha, invite_code });
export const loginUser = ({ email, password }) =>
  usersAPI.post("/login/", { email, password });
export const logoutUser = () => usersAPI.post("/logout/");
export const fetchCurrentUser = () => usersAPI.get("/whoami/");
export const fetchUsers = ({ itemsPerPage = 1000 * 1000, ...params } = {}) =>
  api.get("/users/", {
    params: {
      limit: itemsPerPage,
      ...params,
    },
  });
export const createUser = data => api.post("/users/", data);
export const updateUser = (id, data) => api.patch(`/users/${id}/`, data);
export const deleteUser = id => api.delete(`/users/${id}/`);
export const getPasswordResetLink = ({ email }) =>
  usersAPI.post("/request_password_reset/", { email });
export const resetPassword = data => usersAPI.post("/password_reset/", data);
export const signupVerify = data => usersAPI.post("/signup_verify/", data);
export const fetchRoles = () => api.get("/roles/");
export const requestBoosterQuota = id =>
  api.post(`/workspaces/${id}/give_booster_quota/`);

export const getCreateDocumentEndpoint = () => {
  return baseURL + "/documents/";
};

export const fetchSnippets = ({ itemsPerPage = 1000 * 1000 } = {}) =>
  api.get(`/snippets/`, {
    params: {
      limit: itemsPerPage,
    },
  });

/**
 * Fetch snippets for a specific document.
 *
 * @param {number} documentId - ID of the document.
 * @param {object} [options] - Optional config.
 * @param {number} [options.conceptId] - If only a specific concept should be included.
 * @param {Promise} [options.cancelToken] - Axios cancel token.
 * @param {number} [options.limit] - Maximum number of items to return.
 * @param {number} [options.offset] - From which item index to start.
 *
 * @return {Promise}
 */
export const fetchDocumentSnippets = (
  documentId,
  {
    conceptId = null,
    workspaceId,
    cancelToken = null,
    limit = 10,
    offset = null,
  } = {}
) => {
  const params = {
    document_id: documentId,
    workspace: workspaceId,
  };

  if (conceptId) params.snippet_type_id = conceptId;
  if (limit) params.limit = limit;
  if (offset) params.offset = offset;

  return api.get("/snippets/", {
    params,
    cancelToken,
  });
};

export const updateDocumentSnippetComment = (id, comment) =>
  api.patch(`/snippets/${id}/`, {
    comment,
  });

export const updateDocumentSnippet = (id, { conceptId = undefined, ...rest }) =>
  api.patch(
    `/snippets/${id}/`,
    omitUndefinedValues({ snippet_type_id: conceptId, ...rest })
  );

export const deleteSnippet = id => api.delete(`/snippets/${id}/`);
export const deleteSnippets = ids => Promise.all(ids.map(deleteSnippet));

export const createSnippet = data => api.post(`/snippets/`, data);

export const fetchSnippetSuggestions = ({
  documentId,
  conceptId,
  workspaceId,
  cancelToken,
}) =>
  api.get(
    `/snippet-suggestions/?document_id=${documentId}&snippet_type_id=${conceptId}&workspace=${workspaceId}`,
    {
      cancelToken,
    }
  );

export const getCSVReportURL = () => `${baseURL}/report.csv`;
export const getXLSXReportURL = () => `${baseURL}/report.xlsx`;

export const fetchDocumentLabels = () => api.get(`/document-labels/`);

export const createDocumentLabel = data => api.post(`/document-labels/`, data);

export const deleteDocumentLabel = id => api.delete(`/document-labels/${id}/`);

export const addDocumentLabels = (id, data) =>
  api.post(`/document-labels/${id}/`, data);

export const addDocumentsByDocumentLabel = (labelId, data) =>
  api.post(`/document-labels/${labelId}/documents/`, data);

export const updateDocumentLabel = (labelId, data) =>
  api.patch(`/document-labels/${labelId}/`, data);

export const removeDocumentLabelsByDocument = (documentId, data) =>
  api.delete(`/documents/${documentId}/labels/`, {
    data: data,
  });

export const createLabelType = data => api.post(`/label-types/`, data);

export const fetchLabelTypes = (params = {}, options = {}) =>
  api.get(`/label-types/`, { params, ...options });

export const patchLabelType = ({ id, data }) =>
  api.patch(`/label-types/${id}/`, data);

export const deleteLabelType = id => api.delete(`/label-types/${id}/`);

export const fetchLabel = (id, params) => api.get(`/labels/${id}/`, params);

export const fetchLabels = (params = {}) =>
  api.get(`/labels/?limit=10000`, { params });

export const createLabel = data => api.post(`/labels/`, data);

export const deleteLabel = id => api.delete(`/labels/${id}/`);

export const patchLabel = (id, data) => api.patch(`/labels/${id}/`, data);

export const assignLabelsToDocument = (documentId, labels) =>
  api.post(`/documents/${documentId}/labels/`, labels);

export const addDocumentsToLabel = (labelId, documentIds = []) =>
  api.post(`/labels/${labelId}/labelled_items/`, {
    document: documentIds,
  });

export const addConceptsToLabel = ({ labelId, snippetIds = [] }) =>
  api.post(`/labels/${labelId}/labelled_items/`, {
    snippettype: snippetIds,
  });

export const updateLabelConcepts = ({ labelId, snippetIds = [] }) =>
  api.patch(`/labels/${labelId}/labelled_items/`, {
    snippettype: snippetIds,
  });

export const fetchReports = ({
  itemsPerPage = 1000 * 1000,
  cancelToken,
} = {}) =>
  api.get(`/reports/`, {
    cancelToken,
    params: {
      limit: itemsPerPage,
    },
  });

export const fetchReport = (id, params) => api.get(`/reports/${id}/`, params);

export const createReport = data => api.post(`/reports/`, data);

export const searchSnippets = (
  query,
  labels = [],
  size = 50,
  from = 0,
  extraParams = {}
) =>
  api.post(
    `/reports/search`,
    Object.assign(
      {
        query,
        labels,
        size,
        from,
      },
      extraParams
    )
  );

export const searchSimilarSegments = (
  text,
  labels = [],
  tags = null,
  tags_exclude = null,
  level = 0
) => {
  let opts = {
    text,
    labels,
    level: Math.max(0, level),
  };
  if (tags) {
    opts["tags"] = tags;
  }
  if (tags_exclude) {
    opts["tags_exclude"] = tags_exclude;
  }
  return api.post(`/reports/similar_segments/`, opts);
};

export const patchReportMeta = (id, meta, labels) =>
  api.patch(`/reports/${id}/`, {
    meta,
    labels,
  });

export const getCheckoutUrl = (workspaceId, priceId) =>
  api.get("/stripe/checkout/", {
    params: { workspace: workspaceId, price_id: priceId },
  });
export const getCustomerPortalUrl = workspaceId =>
  api.get("/stripe/customer_portal/", {
    params: { workspace: workspaceId },
  });
