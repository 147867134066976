/* eslint-disable react/display-name */

import React, { forwardRef } from "react";
import classNames from "classnames";
import type * as Polymorphic from "@radix-ui/react-polymorphic";

import { Box } from "@/components";

import "./WorkspaceIcon.styles.scss";

/**
 * Icon for representing a workspace.
 */
const WorkspaceIcon = forwardRef(
  ({ name, className = "", as = "div", ...props }, ref) => (
    <Box
      ref={ref}
      className={classNames("WorkspaceIcon", className)}
      {...props}
      as={as}
    >
      {name.charAt(0).toUpperCase()}
    </Box>
  )
) as Polymorphic.ForwardRefComponent<"div", WorkspaceIconProps>;

WorkspaceIcon.displayName = "WorkspaceIcon";

export interface WorkspaceIconProps
  extends Omit<React.BaseHTMLAttributes<HTMLDivElement>, "color"> {
  /**
   * Name of the workspace.
   */
  name: string;
  /**
   * Component to render as.
   */
  as?: keyof JSX.IntrinsicElements;
}

export default WorkspaceIcon;
