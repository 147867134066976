/* eslint-disable react/display-name */

import "./LoadingButton.styles.scss";

import classNames from "classnames";
import React, { forwardRef } from "react";

import { useSafeState } from "@/hooks";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Box from "../Box";
import Button, { ButtonProps } from "../Button";

import type * as Polymorphic from "@radix-ui/react-polymorphic";
/**
 * Button with built-in loading spinner.
 *
 * If function passed to `onClick` prop returns a Promise,
 * it automatically shows a loading spinner until the Promise resolves.
 *
 * @example
 * <LoadingButton onClick={async () => await sleep(500)}>
 *   Save
 * </LoadingButton>
 */
const LoadingButton = forwardRef(
  (
    { onClick, disabled = false, children, className = "", ...props },
    forwardedRef
  ) => {
    const [isLoading, setIsLoading] = useSafeState(false);

    return (
      <Button
        className={classNames(
          "LoadingButton",
          {
            "LoadingButton--Loading": isLoading,
          },
          className
        )}
        ref={forwardedRef}
        onClick={async event => {
          setIsLoading(true);

          if (onClick) {
            await onClick(event);
          }

          setIsLoading(false);
        }}
        disabled={isLoading || disabled}
        {...props}
      >
        {isLoading && (
          <FontAwesomeIcon
            className="LoadingButton__Icon"
            icon={faSpinnerThird}
          />
        )}
        <Box as="span" className="LoadingButton__Children">
          {children}
        </Box>
      </Button>
    );
  }
) as Polymorphic.ForwardRefComponent<"button", LoadingButtonProps>;

LoadingButton.displayName = "LoadingButton";

LoadingButton.defaultProps = {
  /**
   * Button style variant.
   */
  variant: "Primary",
};

export type LoadingButtonProps = ButtonProps;

export default LoadingButton;
