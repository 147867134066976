import { Id, ListPage } from "@/data/types";
import { Workspace } from "@/data/workspaces/types";

/**
 * Create updater function to add workspace to list.
 *
 * @param newWorkspace - The workspace to add.
 *
 * @returns Updater function.
 */
const addToList = (newWorkspace: Workspace) =>
  /**
   * Add workspace to list.
   *
   * @param data - Existing workspace list data.
   *
   * @returns Updated workspace list data.
   */
  (data: ListPage<Workspace>): ListPage<Workspace> => ({
    ...data,
    results: [...data.results, newWorkspace],
  });

/**
 * Create updater function to update workspace in list.
 *
 * @param param0 - Data needed for the update.
 * @param param0.id - Id of the workspace to update.
 * @param param0.updates - The actual data updates to be applied.
 *
 * @returns Updater function.
 */
const updateInList = ({
  id,
  updates,
}: {
  id: Id;
  updates: Partial<Pick<Workspace, "name" | "icon">>;
}) =>
  /**
   * Update workspace in list.
   *
   * @param data - Existing workspace list data.
   *
   * @returns Updated workspace list data.
   */
  (data: ListPage<Workspace>): ListPage<Workspace> => ({
    ...data,
    results: (data?.results ?? []).map(workspace =>
      workspace.id === id ? { ...workspace, ...updates } : workspace
    ),
  });

/**
 * Create updater function to remove workspace from list.
 *
 * @param id - Id of the workspace to remove.
 *
 * @returns Updater function.
 */
const removeFromList = (id: Id) =>
  /**
   * Remove workspace from list.
   *
   * @param data - Existing workspace list data.
   *
   * @returns Updated workspace list data.
   */
  (data: ListPage<Workspace>): ListPage<Workspace> => ({
    ...data,
    results: data.results.filter(workspace => workspace.id !== id),
  });

export default {
  addToList,
  updateInList,
  removeFromList,
};
