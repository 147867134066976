import { WorkspaceColor } from "@/data/workspaces/types";

export const DEFAULT_WORKSPACE_NAME = "New Workspace";

export const WORKSPACE_COLORS = Object.values(WorkspaceColor)
  // enum values also contains backwards mapping, so filter it out
  .filter(value => typeof value !== "string") as WorkspaceColor[];

/**
 * Get random color of workspaces color options.
 */
export const getRandomWorkspaceColor = () =>
  WORKSPACE_COLORS[Math.floor(Math.random() * WORKSPACE_COLORS.length)];

const displayNames = {
  [WorkspaceColor.GRAY]: "Gray",
  [WorkspaceColor.GREEN]: "Green",
  [WorkspaceColor.PINK]: "Pink",
  [WorkspaceColor.BLUE]: "Blue",
  [WorkspaceColor.ORANGE]: "Orange",
  [WorkspaceColor.RED]: "Red",
  [WorkspaceColor.PURPLE]: "Purple",
  [WorkspaceColor.BLACK]: "Black",
  [WorkspaceColor.LIGHT_BLUE]: "Light blue",
  [WorkspaceColor.YELLOW]: "Yellow",
};

/**
 * Get UI display name for workspace icon color
 * that can be shown to the user.
 *
 * @param color - The color code to display the display name for.
 * @returns A textual, user-friendly representation of the color value.
 */
export const getColorDisplayName = (color: WorkspaceColor) =>
  displayNames[color];
