import React, { FC, forwardRef } from "react";
import classNames from "classnames";
import Link from "next/link";

import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";

import {
  Box,
  Button,
  LoadingButton,
  Tooltip,
  Progress,
  Text,
} from "@/components";
import { ButtonProps } from "@/components/Button";
import { ProgressProps } from "@/components/Progress";
import { TextProps } from "@/components/Text";
import WorkspaceIcon, {
  WorkspaceIconProps,
} from "@/features/workspace/components/WorkspaceIcon";
import { useTooltip } from "@/hooks";
import { WorkspaceColor } from "@/data/workspaces/types";

import workspaceGradients from "@/styles/workspace-gradients.scss";
import "./MainMenu.styles.scss";

/**
 * Root wrapper of the menu.
 */
const Root: FC<MainMenuProps.Root> = ({ className = "", ...props }) => (
  <Box className={classNames("MainMenu", className)} {...props} />
);

Root.displayName = "MainMenu.Root";

/**
 * Left column of the menu.
 */
const LeftCol: FC<MainMenuProps.LeftCol> = ({ className = "", ...props }) => (
  <Box className={classNames("MainMenu__LeftCol", className)} {...props} />
);

LeftCol.displayName = "MainMenu.LeftCol";

/**
 * Right column of the menu.
 */
const RightCol: FC<MainMenuProps.RightCol> = ({ className = "", ...props }) => (
  <Box className={classNames("MainMenu__RightCol", className)} {...props} />
);

RightCol.displayName = "MainMenu.RightCol";

/**
 * Fixed header of the right column of the menu.
 */
const RightColHeader: FC<MainMenuProps.RightColHeader> = ({
  className = "",
  ...props
}) => (
  <Box
    className={classNames("MainMenu__RightCol__Header", className)}
    {...props}
  />
);

RightColHeader.displayName = "MainMenu.RightColHeader";

/**
 * Input for editing workspace names.
 */
const WorkspaceNameInput = forwardRef<
  HTMLInputElement,
  MainMenuProps.WorkspaceNameInput
>(({ className = "", ...props }, ref) => (
  <input
    id="WorkspaceNameInput"
    ref={ref}
    className={classNames("MainMenu__WorkspaceNameInput", className)}
    {...props}
  />
));

WorkspaceNameInput.displayName = "MainMenu.WorkspaceNameInput";

/**
 * Scrollable body of the right column of the menu.
 */
const RightColBody: FC<MainMenuProps.RightColBody> = ({
  className = "",
  ...props
}) => (
  <Box
    className={classNames("MainMenu__RightCol__Body", className)}
    {...props}
  />
);

RightColBody.displayName = "MainMenu.RightColBody";

/**
 * Wrapper for list of navigation links.
 */
const Nav: FC<MainMenuProps.Nav> = ({ className = "", ...props }) => (
  <nav className={classNames("MainMenu__Nav", className)} {...props} />
);

Nav.displayName = "MainMenu.Nav";

/**
 * List of navigation links.
 */
const NavList: FC<MainMenuProps.NavList> = ({ className = "", ...props }) => (
  <ul className={classNames("MainMenu__Nav__List", className)} {...props} />
);

NavList.displayName = "MainMenu.NavList";

/**
 * Navigation links list item.
 */
const NavListItem: FC<MainMenuProps.NavListItem> = ({
  className = "",
  ...props
}) => (
  <li
    className={classNames("MainMenu__Nav__List__Item", className)}
    {...props}
  />
);

NavListItem.displayName = "MainMenu.NavListItem";

/**
 * Actual link inside of a navigation links list item.
 */
const NavListItemLink = forwardRef<
  HTMLAnchorElement,
  MainMenuProps.NavListItemLink
>(({ className = "", isSelected, ...props }, ref) => (
  <a
    ref={ref}
    className={classNames(
      "MainMenu__Nav__List__Item__Link",
      {
        "MainMenu__Nav__List__Item__Link--Selected": isSelected,
      },
      className
    )}
    {...props}
  />
));

NavListItemLink.displayName = "MainMenu.NavListItemLink";

/**
 * Icon for a navigation links list item.
 */
const NavListItemLinkIcon: FC<MainMenuProps.NavListItemLinkIcon> = ({
  className = "",
  ...props
}) => (
  <FontAwesomeIcon
    className={classNames("MainMenu__Nav__List__Item__Link__Icon", className)}
    {...props}
  />
);

NavListItemLinkIcon.displayName = "MainMenu.NavListItemLinkIcon";

/**
 * Section title text component to divide menu body into sections.
 */
const SectionTitle: FC<MainMenuProps.SectionTitle> = ({
  className = "",
  ...props
}) => (
  <h3 className={classNames("MainMenu__SectionTitle", className)} {...props} />
);

SectionTitle.displayName = "MainMenu.SectionTitle";

/**
 * Icon for a menu section title.
 */
const SectionTitleIcon: FC<MainMenuProps.SectionTitleIcon> = ({
  className = "",
  ...props
}) => (
  <FontAwesomeIcon
    className={classNames("MainMenu__SectionTitle__Icon", className)}
    {...props}
  />
);

SectionTitleIcon.displayName = "MainMenu.SectionTitleIcon";

/**
 * Fixed footer of right column of the menu.
 */
const RightColFooter: FC<MainMenuProps.RightColFooter> = ({
  className = "",
  ...props
}) => (
  <Box
    className={classNames("MainMenu__RightCol__Footer", className)}
    {...props}
  />
);

RightColFooter.displayName = "MainMenu.RightColFooter";

/**
 * Wrapper for list of workspaces icons.
 */
const Workspaces: FC<MainMenuProps.Workspaces> = ({
  className = "",
  ...props
}) => (
  <Box className={classNames("MainMenu__Workspaces", className)} {...props} />
);

Workspaces.displayName = "MainMenu.Workspaces";

/**
 * Single workspace icon to choose the active workspace from.
 */
const WorkspacesOption: FC<MainMenuProps.WorkspacesOption> = ({
  name,
  color,
  href,
  isSelected = false,
  className = "",
  onClick = () => {},
}) => {
  const tooltip = useTooltip({ placement: "right", delayShow: 0 });

  return (
    <>
      <Box className={classNames("MainMenu__Workspaces__Option", className)}>
        <Link passHref href={href}>
          <WorkspaceIcon
            as="a"
            className={classNames(
              "MainMenu__Workspaces__Option__Icon",
              className
            )}
            name={name}
            style={{
              background:
                workspaceGradients?.[WorkspaceColor?.[color]?.toLowerCase()],
              color: [WorkspaceColor.GREEN, WorkspaceColor.YELLOW].includes(
                color
              )
                ? "#131e27"
                : "white",
            }}
            ref={tooltip.setTriggerRef}
            onClick={onClick}
          />
        </Link>
        {isSelected && (
          <Box className="MainMenu__Workspaces__Option__SelectedIndicator" />
        )}
      </Box>

      <Tooltip
        {...tooltip.getTooltipProps({
          colorScheme: "Purple",
          ref: tooltip.setTooltipRef,
        })}
      >
        {name}
      </Tooltip>
    </>
  );
};

WorkspacesOption.displayName = "MainMenu.WorkspacesOption";

/**
 * Button to add new workspace.
 */
const WorkspacesAddButton: FC<MainMenuProps.WorkspacesAddButton> = ({
  className = "",
  ...props
}) => {
  const tooltip = useTooltip({ placement: "right" });

  return (
    <>
      <LoadingButton
        className={classNames("MainMenu__Workspaces__AddButton", className)}
        ref={tooltip.setTriggerRef}
        {...props}
      >
        <FontAwesomeIcon icon={faPlus} />
      </LoadingButton>

      <Tooltip
        {...tooltip.getTooltipProps({
          colorScheme: "Purple",
          ref: tooltip.setTooltipRef,
          className: classNames("MainMenu__Workspaces__AddButtonTooltip"),
        })}
      >
        Create new workspace
      </Tooltip>
    </>
  );
};

WorkspacesAddButton.displayName = "MainMenu.WorkspacesAddButton";

/**
 * Wrapper for page quota info section.
 */
const QuotaInfo: FC<MainMenuProps.QuotaInfo> = ({
  className = "",
  ...props
}) => (
  <Box className={classNames("MainMenu__QuotaInfo", className)} {...props} />
);

QuotaInfo.displayName = "MainMenu.QuotaInfo";

/**
 * Page quota info label wrapper component.
 */
const QuotaInfoLabel: FC<MainMenuProps.QuotaInfoLabel> = ({
  className = "",
  ...props
}) => (
  <Box
    className={classNames("MainMenu__QuotaInfo__Label", className)}
    {...props}
  />
);

QuotaInfoLabel.displayName = "MainMenu.QuotaInfoLabel";

/**
 * Actual label text for page quota info.
 */
const QuotaInfoLabelText: FC<MainMenuProps.QuotaInfoLabelText> = ({
  className = "",
  ...props
}) => (
  <Text
    className={classNames("MainMenu__QuotaInfo__Label__Text", className)}
    variant="Body2"
    {...props}
  />
);

QuotaInfoLabelText.displayName = "MainMenu.QuotaInfoLabelText";

/**
 * Icon for page quota info label.
 */
const QuotaInfoLabelIcon: FC<MainMenuProps.QuotaInfoLabelIcon> = ({
  tooltipChildren,
  className = "",
  ...props
}) => {
  const tooltip = useTooltip({ placement: "right" });

  return (
    <Box ref={tooltip.setTriggerRef}>
      <FontAwesomeIcon
        className={classNames("MainMenu__QuotaInfo__Label__Icon", className)}
        {...props}
      />
      <Tooltip
        {...tooltip.getTooltipProps({
          ref: tooltip.setTooltipRef,
        })}
      >
        {tooltipChildren}
      </Tooltip>
    </Box>
  );
};

QuotaInfoLabelIcon.displayName = "MainMenu.QuotaInfoLabelIcon";

/**
 * Progress bar displaying percentage of used page quota.
 */
const QuotaInfoProgressBar: FC<MainMenuProps.QuotaInfoProgressBar> = ({
  className = "",
  ...props
}) => (
  <Progress
    className={classNames("MainMenu__QuotaInfo__ProgressBar", className)}
    background="Dark"
    foreground="Blue"
    {...props}
  />
);

QuotaInfoProgressBar.displayName = "MainMenu.QuotaInfoProgressBar";

/**
 * Page quota info button for actions like upgrading quota.
 */
const QuotaInfoButton: FC<MainMenuProps.QuotaInfoButton> = ({
  className = "",
  ...props
}) => (
  <Button
    className={classNames("MainMenu__QuotaInfo__Button", className)}
    variant="Tertiary"
    {...props}
  />
);

QuotaInfoButton.displayName = "MainMenu.QuotaInfoButton";

export namespace MainMenuProps {
  export type Root = React.BaseHTMLAttributes<HTMLDivElement>;
  export type LeftCol = React.BaseHTMLAttributes<HTMLDivElement>;
  export type Workspaces = React.BaseHTMLAttributes<HTMLDivElement>;
  export type WorkspacesOption = WorkspaceIconProps & {
    isSelected?: boolean;
    href: string;
    color: WorkspaceColor;
  };
  export type WorkspacesAddButton = ButtonProps;
  export type RightCol = React.BaseHTMLAttributes<HTMLDivElement>;
  export type RightColHeader = React.BaseHTMLAttributes<HTMLDivElement>;
  export type WorkspaceNameInput = React.InputHTMLAttributes<HTMLInputElement>;
  export type RightColBody = React.BaseHTMLAttributes<HTMLDivElement>;
  export type SectionTitle = React.BaseHTMLAttributes<HTMLHeadingElement>;
  export type SectionTitleIcon = FontAwesomeIconProps;
  export type Nav = React.BaseHTMLAttributes<HTMLDivElement>;
  export type NavList = React.BaseHTMLAttributes<HTMLUListElement>;
  export type NavListItem = React.LiHTMLAttributes<HTMLLIElement>;
  export type NavListItemLink = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    isSelected?: boolean;
  };
  export type NavListItemLinkIcon = FontAwesomeIconProps;
  export type RightColFooter = React.BaseHTMLAttributes<HTMLDivElement>;
  export type QuotaInfo = React.BaseHTMLAttributes<HTMLDivElement>;
  export type QuotaInfoLabel = React.BaseHTMLAttributes<HTMLDivElement>;
  export type QuotaInfoLabelText = React.BaseHTMLAttributes<HTMLParagraphElement> &
    TextProps;
  export type QuotaInfoLabelIcon = FontAwesomeIconProps & {
    tooltipChildren: any;
  };
  export type QuotaInfoProgressBar = ProgressProps;
  export type QuotaInfoButton = ButtonProps;
}

export default {
  Root,
  LeftCol,
  Workspaces,
  WorkspacesOption,
  WorkspacesAddButton,
  RightCol,
  RightColHeader,
  WorkspaceNameInput,
  RightColBody,
  SectionTitle,
  SectionTitleIcon,
  Nav,
  NavList,
  NavListItem,
  NavListItemLink,
  NavListItemLinkIcon,
  RightColFooter,
  QuotaInfo,
  QuotaInfoLabel,
  QuotaInfoLabelText,
  QuotaInfoLabelIcon,
  QuotaInfoProgressBar,
  QuotaInfoButton,
};
