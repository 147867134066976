import { useCallback } from "react";
import { usePopperTooltip } from "react-popper-tooltip";

export type Placement =
  | "auto"
  | "auto-start"
  | "auto-end"
  | "top"
  | "top-start"
  | "top-end"
  | "bottom"
  | "bottom-start"
  | "bottom-end"
  | "right"
  | "right-start"
  | "right-end"
  | "left"
  | "left-start"
  | "left-end";

/**
 * Convenience wrapper around `react-popper-tooltip` library.
 *
 * See: https://github.com/mohsinulhaq/react-popper-tooltip
 *
 * @param param0 - Optional config.
 * @param param0.placement - The preferred placement of the tooltip if there
 * is enough space for it at that position.
 *
 * @returns - Ref setter and prop getter functions.
 */
const useTooltip = ({
  placement = "bottom",
  delayShow = 500,
}: { placement?: Placement; delayShow?: number } = {}) => {
  const {
    visible,
    setTriggerRef,
    setTooltipRef,
    getTooltipProps: originalGetTooltipProps,
    getArrowProps,
  } = usePopperTooltip({
    delayShow,
    offset: [0, 10],
    placement,
  });

  const getTooltipProps = useCallback(
    (props = {}) => ({
      show: visible,
      ...originalGetTooltipProps(props),
      arrowProps: getArrowProps(),
    }),
    [visible, originalGetTooltipProps, getArrowProps]
  );

  return {
    setTriggerRef,
    setTooltipRef,
    getTooltipProps,
  };
};

export default useTooltip;
