import { useMutation, UseMutationOptions, useQueryClient } from "react-query";

import { Id, ListPage } from "@/data/types";
import { Workspace } from "@/data/workspaces/types";
import { useCurrentWorkspace } from "@/data/workspaces/queries";
import api from "@/data/workspaces/api";
import keys from "@/data/workspaces/keys";
import optimistic from "@/data/workspaces/optimistic";
import { HIGH_LIST_PAGE_SIZE } from "@/constants";

const LIST_CALL_PARAMS = { page: 0, pageSize: HIGH_LIST_PAGE_SIZE };

/**
 * Hook for creating a workspace.
 *
 * @param options - react-query mutation hook options.
 */
export const useCreateWorkspace = (
  options: UseMutationOptions<
    Workspace,
    Error,
    Pick<Workspace, "name" | "icon">
  > = {}
) => {
  const queryClient = useQueryClient();

  return useMutation<Workspace, Error, Pick<Workspace, "name" | "icon">>(
    ({ name, icon }) => api.create({ name, icon }),
    {
      onSettled: newWorkspace => {
        queryClient.setQueryData<ListPage<Workspace>>(
          keys.list(LIST_CALL_PARAMS),
          optimistic.addToList(newWorkspace)
        );

        queryClient.invalidateQueries(keys.all(), { exact: false });
      },
      ...options,
    }
  );
};

/**
 * Hook for updating a workspace.
 *
 * @param options - react-query mutation hook options.
 */
export const useUpdateWorkspace = (
  options: UseMutationOptions<
    Workspace,
    Error,
    { id: Id; updates: Partial<Pick<Workspace, "name" | "icon">> }
  > = {}
) => {
  const queryClient = useQueryClient();

  return useMutation<
    Workspace,
    Error,
    { id: Id; updates: Partial<Pick<Workspace, "name" | "icon">> }
  >(({ id, updates }) => api.update(id, updates), {
    onMutate: async ({ id, updates }) => {
      await queryClient.cancelQueries(keys.detail(id));

      // optimistically update detail query cache
      queryClient.setQueryData<Workspace>(keys.detail(id), data => ({
        ...data,
        ...updates,
      }));

      // optimistically update list query cache
      queryClient.setQueryData<ListPage<Workspace>>(
        keys.list(LIST_CALL_PARAMS),
        optimistic.updateInList({ id, updates })
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(keys.all(), { exact: false });
    },
    ...options,
  });
};

/**
 * Hook for deleting a workspace.
 *
 * @param options - react-query mutation hook options.
 */
export const useDeleteWorkspace = (
  options: UseMutationOptions<void, Error, Id> = {}
) => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, Id>(api.deleteById, {
    onMutate: async id => {
      await queryClient.cancelQueries(keys.detail(id));

      // optimistically update list query cache
      queryClient.setQueryData<ListPage<Workspace>>(
        keys.list(LIST_CALL_PARAMS),
        optimistic.removeFromList(id)
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(keys.all(), { exact: false });
    },
    ...options,
  });
};

/**
 * Hook for adding a user to the currently active workspace by email.
 *
 * @param options - react-query mutation hook options.
 */
export const useAddUserToCurrentWorkspace = (
  options: UseMutationOptions<void, Error, string> = {}
) => {
  const workspaceQuery = useCurrentWorkspace();

  return useMutation<void, Error, string>(
    (email: string) => api.addUser(workspaceQuery.data.id, email),
    options
  );
};

/**
 * Hook for removing a user from the currently active workspace.
 *
 * @param options - react-query mutation hook options.
 */
export const useRemoveUserFromCurrentWorkspace = (
  options: UseMutationOptions<void, Error, Id> = {}
) => {
  const workspaceQuery = useCurrentWorkspace();

  return useMutation<void, Error, Id>(
    (userId: Id) => api.removeUser(workspaceQuery.data.id, userId),
    options
  );
};
