import { Id, SubscriptionStatus } from "@/data/types";

/* eslint-disable no-unused-vars */
export enum WorkspaceColor {
  GRAY = 1,
  GREEN = 2,
  PINK = 3,
  BLUE = 4,
  ORANGE = 5,
  RED = 6,
  PURPLE = 7,
  BLACK = 8,
  LIGHT_BLUE = 9,
  YELLOW = 10,
}
/* eslint-enable no-unused-vars */

/**
 * A Lateral Workspace.
 */
declare interface WorkspaceBase {
  /**
   * Id of the workspace.
   */
  id: Id;
  /**
   * Icon color of the workspace.
   */
  icon: WorkspaceColor;
  /**
   * Name of the workspace.
   */
  name: string;
  /**
   * Ids of users that are part of the workspace.
   */
  users: Id[];
  /**
   * Whether the workspace has an active subscription.
   */
  subscribed: boolean;
}

export interface Workspace extends WorkspaceBase {
  /**
   * Whether the workspace has access to digidocs processing.
   */
  hasDigiDocsAccess: boolean;
  /**
   * The total digidocs page quota the workspace has available.
   */
  pageQuota: number;
  /**
   * Amount of digidocs page quota remaining for the workspace.
   */
  pageQuotaRemaining: number;
  /**
   * The total OCR page quota the workspace has available.
   */
  ocrQuota: number;
  /**
   * Amount of OCR page quota remaining for the workspace.
   */
  ocrQuotaRemaining: number;
  /**
   * Whether the workspace was given the one-time booster quota.
   */
  hasReceivedBoosterQuota: boolean;
  /**
   * Whether the workspace was given the one-time OCR booster quota.
   */
  hasReceivedBoosterOcrQuota: boolean;
  /**
   * When the workspace was created.
   */
  createdAt: string;
  /**
   * Who is allowed to administer billing.
   */
  billingAdmin: Id;
  /**
   * Whether the workspace is subscribed.
   */
  subscriptionStatus: SubscriptionStatus;
}

export interface WorkspaceJSON extends WorkspaceBase {
  use_ocr: boolean;
  page_quota: number;
  page_quota_remaining: number;
  ocr_quota: number;
  ocr_quota_remaining: number;
  has_received_booster_quota: boolean;
  has_received_booster_ocr_quota: boolean;
  created_at: string;
  billing_admin: Id;
  subscription_status: SubscriptionStatus;
}
